import React, { useState, useEffect } from 'react';
import { Button } from './ui/button';
import { Textarea } from './ui/textarea';
import { Card, CardHeader, CardTitle, CardContent, CardFooter } from './ui/card';

const EditPrompt = ({ initialPrompt, onSave, onNext }) => {
  const [prompt, setPrompt] = useState('');

  useEffect(() => {
    setPrompt(initialPrompt || '');
  }, [initialPrompt]);

  const handleSave = () => {
    onSave(prompt);
    onNext();
  };

  return (
    <Card className="w-full max-w-2xl mx-auto">
      <CardHeader>
        <CardTitle>Edit Prompt</CardTitle>
      </CardHeader>
      <CardContent>
        <Textarea
          value={prompt}
          onChange={(e) => setPrompt(e.target.value)}
          placeholder="Enter or edit the prompt here..."
          rows={10}
          className="w-full"
        />
      </CardContent>
      <CardFooter className="flex justify-end">
        <Button onClick={handleSave}>Save and Continue</Button>
      </CardFooter>
    </Card>
  );
};

export default EditPrompt;
