import React, { Suspense } from 'react';
import { Spinner } from './ui/spinner';

const AssessmentPDF = React.lazy(() => import('./AssessmentPDF'));

const LazyAssessmentPDF = (props) => (
  <Suspense fallback={<Spinner />}>
    <AssessmentPDF {...props} />
  </Suspense>
);

export default LazyAssessmentPDF;

