import React, { useState, useEffect, useContext } from 'react';
import { AlertCircle, Save, Share, ChevronLeft, ChevronRight, FileDown } from 'lucide-react';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '../components/ui/card';
import { Button } from '../components/ui/button';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../components/ui/select';
import { Alert, AlertDescription, AlertTitle } from '../components/ui/alert';
import { RadioGroup, RadioGroupItem } from '../components/ui/radio-group';
import { Label } from '../components/ui/label';
import { Input } from '../components/ui/input';
import { Progress } from '../components/ui/progress';
import { Checkbox } from '../components/ui/checkbox';
import { PDFDownloadLink } from '@react-pdf/renderer';
import LazyAssessmentPDF from './LazyAssessmentPDF';
import OSINTDataHandler from './OSINTDataHandler';
import { analyzeResponses, assignMaturityScore, createActionPlan } from '../utils/chatgptUtils';
import { loadQuestions } from '../utils/questionLoader';
import translations from '../translations';
import { AuthContext } from '../contexts/AuthContext';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from './ui/dialog';
import { Tabs, TabsContent, TabsList, TabsTrigger } from './ui/tabs';
import { Textarea } from './ui/textarea';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from './ui/accordion';
import { debounce } from '../utils/debounce';
import { HoverCard, HoverCardContent, HoverCardTrigger } from './ui/hover-card';
import { FaQuestionCircle } from 'react-icons/fa';

const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:5001';
console.log('API_BASE_URL:', API_BASE_URL);

const CyberAssessment = ({ language, userId }) => {
  const t = (key) => {
    const translation = translations[language][key];
    return translation || key; // Return the key if translation is missing
  };
  const { user } = useContext(AuthContext);
  const [stage, setStage] = useState('setup');
  const [orgSize, setOrgSize] = useState('');
  const [orgSector, setOrgSector] = useState('');
  const [maturity, setMaturity] = useState('');
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState({});
  const [progress, setProgress] = useState(0);
  const [autoSaveInterval, setAutoSaveInterval] = useState(null);
  const [osintData, setOsintData] = useState(null);
  const [hasOsintData, setHasOsintData] = useState('no');
  const [showRecommendations, setShowRecommendations] = useState(false);
  const [showReportAndPromptDialog, setShowReportAndPromptDialog] = useState(false);
  const [selectedAssessment, setSelectedAssessment] = useState(null);
  const [promptInput, setPromptInput] = useState('');
  const [collaborationLink, setCollaborationLink] = useState('');
  const [questions, setQuestions] = useState([]);
  const [error, setError] = useState(null);
  const [optInAdvanced, setOptInAdvanced] = useState(false);
  const [showAdvancedQuestions, setShowAdvancedQuestions] = useState(false);
  const [assessment, setAssessment] = useState(null);
  const [reportRequested, setReportRequested] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [selectedFramework, setSelectedFramework] = useState('');
  const [helperData, setHelperData] = useState(null);
  const currentQuestionData = questions[currentQuestionIndex];
  const [assessmentNotes, setAssessmentNotes] = useState({});
  const [saveStatus, setSaveStatus] = useState('');

// Add helper dialogues to User Assessments
  useEffect(() => {
    if (currentQuestion < questions.length) {
      const currentQuestionData = questions[currentQuestion];
      console.log('Current question data:', currentQuestionData);
      if (currentQuestionData && currentQuestionData.id) {
        console.log(`Fetching helper data for question ID: ${currentQuestionData.id}`);
        fetchHelperData(currentQuestionData.id);
      } else {
        console.error('Current question data is invalid:', currentQuestionData);
        setHelperData(null);
      }
    }
  }, [currentQuestion, questions]);

  const fetchHelperData = async (questionId) => {
    try {
      console.log(`Fetching helper data for question ID: ${questionId}`);
      const category = questions[currentQuestion].category;
      const helperFileName = `${category}-helper.json`;
      
      const helperModule = await import(`../questions/${helperFileName}`);
      const helperData = helperModule.default;
      
      const questionHelper = helperData.find(item => item.id === questionId);
      
      if (questionHelper) {
        console.log('Fetched helper data:', questionHelper);
        setHelperData(questionHelper);
      } else {
        console.error(`Helper data not found for question ID: ${questionId}`);
        setHelperData(null);
      }
    } catch (error) {
      console.error('Error fetching helper data:', error);
      setHelperData(null);
    }
  };

useEffect(() => {
    const fetchAssessment = async () => {
      try {
        const token = localStorage.getItem('token');
        const endpoint = `${API_BASE_URL}/api/assessments`;
        const response = await fetch(endpoint, {
          headers: {
            'x-auth-token': token
          }
        });
        if (response.ok) {
          const data = await response.json();
          setAssessment(data);
          setAnswers(data.answers || {});
          setOrgSize(data.orgSize || '');
          setOrgSector(data.orgSector || '');
          setMaturity(data.maturity || '');
          setSelectedFramework(data.selectedFramework || '');
          setAssessmentNotes(data.assessmentNotes || {}); // Add this line
        } else if (response.status === 404) {
          console.log('No existing assessment found');
        } else {
          console.error('Failed to fetch assessment:', await response.text());
        }
      } catch (error) {
        console.error('Error fetching assessment:', error);
        setError('Failed to fetch existing assessment. Please try again.');
      }
    };
    fetchAssessment();

    const savedAnswers = localStorage.getItem('cyberAssessmentAnswers');
    if (savedAnswers) {
      setAnswers(JSON.parse(savedAnswers));
    }
    const savedOrgSize = localStorage.getItem('orgSize');
    if (savedOrgSize) {
      setOrgSize(savedOrgSize);
    }
    const savedOrgSector = localStorage.getItem('orgSector');
    if (savedOrgSector) {
      setOrgSector(savedOrgSector);
    }
    const savedMaturity = localStorage.getItem('maturity');
    if (savedMaturity) {
      setMaturity(savedMaturity);
    }
    const savedFramework = localStorage.getItem('selectedFramework');
    if (savedFramework) {
      setSelectedFramework(savedFramework);
    }
    const savedAssessmentNotes = localStorage.getItem('cyberAssessmentNotes');
    if (savedAssessmentNotes) {
      setAssessmentNotes(JSON.parse(savedAssessmentNotes));
    }
  }, [userId]);

  useEffect(() => {
    updateProgress();
  }, [stage, currentQuestion, orgSize, orgSector, answers, assessmentNotes, questions, maturity, selectedFramework]);

  useEffect(() => {
    const saveInterval = setInterval(() => {
      handleSaveProgress();
    }, 60000);

    return () => clearInterval(saveInterval);
  }, [answers, assessmentNotes, orgSize, orgSector, maturity, selectedFramework]);

useEffect(() => {
  const fetchQuestions = async () => {
    try {
      const categories = ['general'];
      if (orgSize) categories.push(`size-${orgSize.toLowerCase()}`);
      if (orgSector) categories.push(`sector-${orgSector.toLowerCase()}`);
      if (maturity) categories.push(`maturity-${maturity.toLowerCase()}`);
      if (selectedFramework) categories.push(selectedFramework.toLowerCase());
      console.log('Fetching categories:', categories);
      const loadedQuestions = await loadQuestions(categories);
      console.log('Loaded questions:', loadedQuestions);
      if (loadedQuestions.length === 0) {
        setError('No questions loaded. Please check your question files.');
      } else {
        setQuestions(loadedQuestions);
        setError(null);
      }
    } catch (error) {
      console.error('Error fetching questions:', error);
      setError('Failed to load questions. Please try again later.');
    }
  };

  if (orgSize && orgSector && maturity && selectedFramework) {
    fetchQuestions();
  }
}, [orgSize, orgSector, maturity, selectedFramework]);

const viewFullReport = async (assessmentId) => {
  try {
    const token = localStorage.getItem('token');
    const url = `${API_BASE_URL}/api/assessments/${assessmentId}/report`;
    const response = await fetch(url, {
      headers: { 'x-auth-token': token }
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const reportData = await response.json();
    
    // Fetch all questions
    const allCategories = ['general', `size-${reportData.orgSize.toLowerCase()}`, `sector-${reportData.orgSector.toLowerCase()}`];
    
    // Add maturity category if it exists
    if (reportData.maturity) {
      allCategories.push(`maturity-${reportData.maturity.toLowerCase()}`);
    }
    
    // Add framework category if it exists
    if (reportData.selectedFramework) {
      allCategories.push(`framework-${reportData.selectedFramework.toLowerCase()}`);
    }
    
    const allQuestions = await loadQuestions(allCategories);

    setSelectedAssessment({...reportData, questions: allQuestions});
    console.log('Full report data:', {...reportData, questions: allQuestions});
  } catch (error) {
    console.error('Error fetching full report:', error);
    setError(`Failed to fetch full report: ${error.message}`);
  }
};

  const updateProgress = () => {
    if (questions.length > 0) {
      setProgress((currentQuestion / questions.length) * 100);
    } else {
      setProgress(0);
    }
  };

const handleAdvancedQuestions = async () => {
  setShowAdvancedQuestions(true);
  try {
    const advancedQuestions = await loadQuestions(['advanced']);
    console.log('Loaded advanced questions:', advancedQuestions);
    if (advancedQuestions.length > 0) {
      setQuestions(prevQuestions => [...prevQuestions, ...advancedQuestions]);
    } else {
      setError('No advanced questions available.');
    }
  } catch (error) {
    console.error('Error loading advanced questions:', error);
    setError('Failed to load advanced questions. Please try again.');
  }
};

const handleFrameworkSelection = async (framework) => {
  setSelectedFramework(framework);
  const frameworkQuestions = await loadQuestions([`framework-${framework.toLowerCase()}`]);
  setQuestions(prevQuestions => {
    const nonDuplicateQuestions = prevQuestions.filter(q => !frameworkQuestions.some(fq => fq.id === q.id));
    return [...frameworkQuestions, ...nonDuplicateQuestions];
  });
};

const handleSaveProgress = async () => {
  try {
    const token = localStorage.getItem('token');
    if (!token) {
      setError('No authentication token found. Please log in again.');
      return;
    }
    console.log('Saving assessment with data:', { answers, assessmentNotes, orgSize, orgSector, maturity, selectedFramework, language });
    const endpoint = `${API_BASE_URL}/api/assessments`;
    const response = await fetch(endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': token,
      },
      body: JSON.stringify({
        answers,
        assessmentNotes,
        orgSize,
        orgSector,
        maturity,
        selectedFramework,
        language,
      }),
    });

    if (response.ok) {
      console.log('Assessment saved successfully');
      setError('');
    } else if (response.status === 401) {
      setError('Your session has expired. Please log in again.');
      // Optionally, redirect to login page or trigger a re-authentication flow
    } else {
      console.error('Failed to save assessment', await response.text());
      setError('Failed to save progress. Please try again.');
    }
  } catch (error) {
    console.error('Error saving assessment:', error);
    setError('Error saving progress. Please check your connection and try again.');
  }
};

const debouncedSaveProgress = debounce(handleSaveProgress, 2000);

useEffect(() => {
  debouncedSaveProgress();
}, [answers, assessmentNotes, orgSize, orgSector, maturity, selectedFramework]);

  const handleShare = () => {
    const link = `https://example.com/share?answers=${encodeURIComponent(JSON.stringify(answers))}&assessmentNotes=${encodeURIComponent(JSON.stringify(assessmentNotes))}&orgSize=${orgSize}&orgSector=${orgSector}`;
    setCollaborationLink(link);
  };

const handleNextQuestion = () => {
  if (currentQuestion < questions.length - 1) {
    setCurrentQuestion(currentQuestion + 1);
  } else if (!showAdvancedQuestions && optInAdvanced) {
    handleAdvancedQuestions();
  } else {
    setShowRecommendations(true);
    setStage('completed');
  }
};

const handlePreviousQuestion = () => {
    if (currentQuestion > 0) {
      setCurrentQuestion(currentQuestion - 1);
  }
};

  const handleAnswerChange = (e, questionId) => {
    setAnswers({
      ...answers,
      [questionId]: e.target.value
    });
  };

const handleRequestFullReport = async () => {
  setError(null);
  try {
    if (!assessment) {
      throw new Error('No assessment found');
    }
    const token = localStorage.getItem('token');
    const response = await fetch(`${API_BASE_URL}/api/assessments/request-report`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': token
      },
      body: JSON.stringify({ assessmentId: assessment._id })
    });

    if (!response.ok) {
      throw new Error('Failed to request report');
    }

    setSuccessMessage('Your report has been requested successfully. An admin will review and generate it soon.');
  } catch (error) {
    console.error('Error requesting full report:', error);
    setError('An error occurred while requesting the full report. Please try again.');
  }
};

const viewFullReportAndPrompt = async (assessmentId) => {
  try {
    const token = localStorage.getItem('token');
    const url = `${API_BASE_URL}/api/assessments/${assessmentId}/report`;
    const response = await fetch(url, {
      headers: { 'x-auth-token': token }
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const reportData = await response.json();
    
    const allCategories = [
      'general', 'advanced',
      `size-${reportData.orgSize.toLowerCase()}`,
      `sector-${reportData.orgSector.toLowerCase()}`,
      `maturity-${reportData.maturity.toLowerCase()}`,
      `framework-${reportData.selectedFramework.toLowerCase()}`
    ].filter(Boolean);
    
    const allQuestions = await loadQuestions(allCategories);

    setSelectedAssessment({...reportData, questions: allQuestions});

    const promptResponse = await fetch(`${API_BASE_URL}/api/assessments/${assessmentId}/review`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-auth-token': token
      },
      body: JSON.stringify({ 
        action: 'get_prompt',
        assessment: reportData,
        questions: allQuestions
      })
    });

    if (!promptResponse.ok) {
      throw new Error('Failed to get prompt');
    }

    const promptData = await promptResponse.json();
    setPromptInput(promptData.prompt);
    setShowReportAndPromptDialog(true);

  } catch (error) {
    console.error('Error fetching full report and prompt:', error);
    setError(`Failed to fetch full report and prompt: ${error.message}`);
  }
};

  const handleOsintDataLoaded = (data) => {
    setOsintData(data);
    setStage('assessment');
  };

  const renderQuestionInput = (question) => {
    switch (question.type) {
      case 'text':
        return (
          <textarea
            className="w-full p-2 border rounded"
            rows="4"
            value={answers[question.id] || ''}
            onChange={(e) => handleAnswerChange(e, question.id)}
            placeholder={t('typeAnswerHere')}
          />
        );
      case 'number':
        return (
          <Input
            type="number"
            value={answers[question.id] || ''}
            onChange={(e) => handleAnswerChange(e, question.id)}
            placeholder={t('enterNumber')}
          />
        );
      case 'boolean':
        return (
          <RadioGroup
            value={answers[question.id] || ''}
            onValueChange={(value) => handleAnswerChange({ target: { value } }, question.id)}
          >
            <div className="flex items-center space-x-2">
              <RadioGroupItem value="true" id={`${question.id}-yes`} />
              <Label htmlFor={`${question.id}-yes`}>{t('yes')}</Label>
            </div>
            <div className="flex items-center space-x-2">
              <RadioGroupItem value="false" id={`${question.id}-no`} />
              <Label htmlFor={`${question.id}-no`}>{t('no')}</Label>
            </div>
          </RadioGroup>
        );
      case 'select':
        return (
          <Select
            value={answers[question.id] || ''}
            onValueChange={(value) => handleAnswerChange({ target: { value } }, question.id)}
          >
            <SelectTrigger className="w-full">
              <SelectValue placeholder={t('selectOption')} />
            </SelectTrigger>
            <SelectContent>
              {question.options[language].map((option) => (
                <SelectItem key={option} value={option}>
                  {option}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        );
      default:
        return null;
    }
  };

  const handleAssessmentNotesChange = (value) => {
    const currentQuestionId = questions[currentQuestion]?.id;
    if (!currentQuestionId) return;
    
    setAssessmentNotes(prevNotes => ({
      ...prevNotes,
      [currentQuestionId]: value
    }));
  };

  const renderHelperData = () => {
    if (!helperData) return null;

    const { assessment_guidance, evidence_examples, scoring_criteria } = helperData;
    const currentQuestionId = questions[currentQuestion]?.id;

    return (
      <div className="mt-6 bg-white rounded-lg p-6 shadow-sm">
        <Accordion>
          <AccordionItem>
            <AccordionTrigger>
              <h3>{t('viewHelperInformation')}</h3>
            </AccordionTrigger>
            <AccordionContent>
              <div className="space-y-4">
                <HelperSection title={t('assessmentGuidance')} content={assessment_guidance[language]} />
                <HelperSection title={t('evidenceExamples')} content={evidence_examples[language]} isList />
                <HelperSection title={t('scoringCriteria')} content={scoring_criteria[language]} />
              </div>
            </AccordionContent>
          </AccordionItem>
        </Accordion>
        
        <div className="mt-6">
          <h4 className="text-lg font-semibold mb-2">{t('assessmentNotes')}</h4>
          <Textarea
            id="assessmentNotes"
            placeholder={t('enterAssessmentNotes')}
            value={assessmentNotes[currentQuestionId] || ''}
            onChange={(e) => handleAssessmentNotesChange(e.target.value)}
            className="mt-1 w-full p-2 border rounded-md"
            rows={4}
          />
        </div>
      </div>
    );
  };

  const HelperSection = ({ title, content, isList = false }) => (
    <div>
      <h5 className="text-md font-semibold mb-1">{title}</h5>
      {isList ? (
        <ul className="list-disc list-inside text-sm text-gray-700">
          {content.map((item, index) => <li key={index}>{item}</li>)}
        </ul>
      ) : (
        <p className="text-sm text-gray-700">{content || 'No information available'}</p>
      )}
    </div>
  );

const TooltipIcon = ({ content }) => (
  <HoverCard>
    <HoverCardTrigger asChild>
      <Button variant="ghost" className="p-0 h-auto ml-2">
        <FaQuestionCircle className="h-4 w-4 text-gray-400 hover:text-gray-600 transition-colors" />
      </Button>
    </HoverCardTrigger>
    <HoverCardContent className="w-80 p-4 shadow-lg rounded-lg border border-gray-200 bg-white">
      <div className="space-y-2 max-h-60 overflow-y-auto pr-2 custom-scrollbar">
        {content.split('\n').map((item, index) => (
          <p key={index} className="text-sm text-gray-700">
            {item.trim().startsWith('•') ? (
              <span>
                <span className="font-semibold">{item.split(':')[0]}:</span>
                {item.split(':')[1]}
              </span>
            ) : (
              item
            )}
          </p>
        ))}
      </div>
    </HoverCardContent>
  </HoverCard>
);

const renderSetup = () => (
  <Card className="max-w-2xl mx-auto">
    <CardHeader>
      <CardTitle>{t('organizationSetup')}</CardTitle>
      <CardDescription>{t('pleaseProvide')}</CardDescription>
    </CardHeader>
    <CardContent>
      <div className="space-y-4">
        <div>
          <div className="flex items-center space-x-2">
            <Label htmlFor="orgSize">{t('organizationSize')}</Label>
            <TooltipIcon content={t('orgSizeTooltip')} />
          </div>
          <Select value={orgSize} onValueChange={setOrgSize}>
            <SelectTrigger id="orgSize">
              <SelectValue placeholder={t('selectSize')} />
            </SelectTrigger>
            <SelectContent>
              {['Small', 'Medium', 'Large'].map((size) => (
                <SelectItem key={size} value={size}>{t(size.toLowerCase())}</SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        <div>
          <div className="flex items-center space-x-2">
            <Label htmlFor="orgSector">{t('organizationSector')}</Label>
            <TooltipIcon content={t('orgSectorTooltip')} />
          </div>
          <Select value={orgSector} onValueChange={setOrgSector}>
            <SelectTrigger id="orgSector">
              <SelectValue placeholder={t('selectSector')} />
            </SelectTrigger>
            <SelectContent>
              {['Finance', 'Healthcare', 'Technology', 'Retail', 'Manufacturing', 'Government', 'Education', 'Energy', 'Telecom', 'Transportation'].map((sector) => (
                <SelectItem key={sector} value={sector}>{t(sector.toLowerCase())}</SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        <div>
          <div className="flex items-center space-x-2">
            <Label htmlFor="maturity">{t('organizationMaturity')}</Label>
            <TooltipIcon content={t('maturityTooltip')} />
          </div>
          <Select value={maturity} onValueChange={setMaturity}>
            <SelectTrigger id="maturity">
              <SelectValue placeholder={t('selectMaturity')} />
            </SelectTrigger>
            <SelectContent>
              {['Basic', 'Intermediate', 'Advanced'].map((level) => (
                <SelectItem key={level} value={level.toLowerCase()}>{t(level.toLowerCase())}</SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        <div>
          <div className="flex items-center space-x-2">
            <Label htmlFor="framework">{t('organizationFramework')}</Label>
            <TooltipIcon content={t('frameworkTooltip')} />
          </div>
          <Select value={selectedFramework} onValueChange={setSelectedFramework}>
            <SelectTrigger id="framework">
              <SelectValue placeholder={t('selectFramework')} />
            </SelectTrigger>
            <SelectContent>
              <SelectItem value="framework-nist171">NIST SP 800-171</SelectItem>
              <SelectItem value="framework-iso27001">ISO/IEC 27001</SelectItem>
              <SelectItem value="framework-cis">CIS Controls</SelectItem>
              <SelectItem value="framework-basiccompliance">Basic Compliance</SelectItem>
            </SelectContent>
          </Select>
        </div>
        <div>
          <div className="flex items-center space-x-2">
            <Label>{t('haveOsintData')}</Label>
            <TooltipIcon content={t('osintTooltip')} />
          </div>
          <RadioGroup onValueChange={setHasOsintData} value={hasOsintData}>
            <div className="flex items-center space-x-2">
              <RadioGroupItem value="yes" id="osint-yes" />
              <Label htmlFor="osint-yes">{t('yes')}</Label>
            </div>
            <div className="flex items-center space-x-2">
              <RadioGroupItem value="no" id="osint-no" />
              <Label htmlFor="osint-no">{t('no')}</Label>
            </div>
          </RadioGroup>
        </div>
      </div>
    </CardContent>
    <CardFooter>
      <Button 
        onClick={() => setStage(hasOsintData === 'yes' ? 'osint' : 'assessment')}
        disabled={!orgSize || !orgSector || !selectedFramework}
        className="w-full"
      >
        {hasOsintData === 'yes' ? t('uploadOsintData') : t('startAssessment')}
      </Button>
    </CardFooter>
  </Card>
);

  const renderOsintUpload = () => (
    <Card>
      <CardHeader>
        <CardTitle>{t('uploadOsintData')}</CardTitle>
        <CardDescription>{t('uploadOsintDescription')}</CardDescription>
      </CardHeader>
      <CardContent>
        <OSINTDataHandler onDataLoaded={handleOsintDataLoaded} language={language} />
      </CardContent>
      <CardFooter>
        <Button onClick={() => setStage('assessment')}>{t('skipOsintUpload')}</Button>
      </CardFooter>
    </Card>
  );

const renderQuestion = () => {
  if (questions.length === 0) {
    return (
      <Card className="max-w-2xl mx-auto">
        <CardContent>
          <p>{t('noQuestionsAvailable')}</p>
        </CardContent>
      </Card>
    );
  }
  
  const currentQuestionData = questions[currentQuestion];
  if (!currentQuestionData) {
    console.error('Current question is undefined:', currentQuestion, questions);
    return (
      <Card className="max-w-2xl mx-auto">
        <CardContent>
          <p>{t('errorLoadingQuestion')}</p>
        </CardContent>
      </Card>
    );
  }

  const isLastQuestion = currentQuestion === questions.length - 1;

  return (
    <Card className="max-w-2xl mx-auto">
      <CardHeader>
        <CardDescription className="text-xl font-semibold">
          {t('questionNumber').replace('{current}', currentQuestion + 1).replace('{total}', questions.length)}
        </CardDescription>
      </CardHeader>
      <CardContent>
        <Progress value={progress} className="mb-4" />
        <p className="mb-4 font-medium">
          {currentQuestionData.text[language] || t(`questions.${currentQuestionData.id}`)}
        </p>
        {renderQuestionInput(currentQuestionData)}
        {renderHelperData()}
        {isLastQuestion && !showAdvancedQuestions && (
          <div className="mt-4">
            <Checkbox 
              id="optInAdvanced"
              checked={optInAdvanced}
              onCheckedChange={(checked) => setOptInAdvanced(checked)}
            />
            <Label htmlFor="optInAdvanced" className="ml-2">
              {t('optInAdvanced')}
            </Label>
          </div>
        )}
      </CardContent>
      <CardFooter className="flex justify-between">
        <Button onClick={handlePreviousQuestion} disabled={currentQuestion === 0} variant="outline">
          <ChevronLeft className="mr-2 h-4 w-4" />
          {t('previous')}
        </Button>
        <Button onClick={handleNextQuestion}>
          {isLastQuestion && !optInAdvanced ? t('finish') : t('next')}
          <ChevronRight className="ml-2 h-4 w-4" />
        </Button>
      </CardFooter>
    </Card>
  );
};

const renderRecommendations = () => {
  return (
    <Card className="max-w-2xl mx-auto">
      <CardHeader>
        <CardTitle>{t('recommendations')}</CardTitle>
        <CardDescription>{t('recommendationsDescription')}</CardDescription>
      </CardHeader>
      <CardContent>
        {!reportRequested ? (
          <Button 
            onClick={handleRequestFullReport} 
            disabled={reportRequested}
            className="w-full mb-4"
          >
            {reportRequested ? t('requestingReport') : t('requestFullReport')}
          </Button>
        ) : (
          <Alert>
            <AlertTitle>Success</AlertTitle>
            <AlertDescription>{successMessage}</AlertDescription>
          </Alert>
        )}
      </CardContent>
      <CardFooter className="flex justify-between">
        <Button 
          onClick={() => {
            setShowRecommendations(false);
            setStage('setup');
            setCurrentQuestion(0);
            setAnswers({});
            setAssessmentNotes({});
          }} 
          variant="outline"
        >
          {t('startNewAssessment')}
        </Button>
        {assessment && (
          <PDFDownloadLink
            document={
              <LazyAssessmentPDF
                orgSize={assessment.orgSize}
                orgSector={assessment.orgSector}
                answers={assessment.answers}
                questions={questions}
                language={language}
                osintData={assessment.osintData}
                analysisResult={assessment.analysisResult}
                maturityScore={assessment.maturityScore}
                selectedFramework={assessment.selectedFramework}
                actionPlan={assessment.actionPlan}
                assessmentNotes={assessment.assessmentNotes}
              />
            }
            fileName={`assessment_report_${assessment.user.customerIdentifier}.pdf`}
          >
            {({ blob, url, loading, error }) => (
              <Button disabled={loading} className="ml-2">
                <FileDown className="mr-2 h-4 w-4" />
                {loading ? t('loadingDocument') : t('downloadPDF')}
              </Button>
            )}
          </PDFDownloadLink>
        )}
      </CardFooter>
    </Card>
  );
};

  return (
    <div className="container mx-auto p-4">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-3xl font-bold">{t('cyberAssessment')}</h1>
        <div className="flex items-center">
          <Button onClick={handleSaveProgress} className="ml-2">
            <Save className="mr-2 h-4 w-4" /> {t('save')}
          </Button>
          {/* Only show share button for regular users */}
            <Button onClick={handleShare} className="ml-2">
              <Share className="mr-2 h-4 w-4" /> {t('share')}
            </Button>
        </div>
      </div>
      
      {collaborationLink && (
        <Alert className="mb-4">
          <AlertCircle className="h-4 w-4" />
          <AlertTitle>{t('collaborationLinkGenerated')}</AlertTitle>
          <AlertDescription>
            {t('shareToCollaborate')}: 
            <a href={collaborationLink} className="underline">{collaborationLink}</a>
          </AlertDescription>
        </Alert>
      )}

      {error && (
        <Alert variant="destructive" className="mb-4">
          <AlertCircle className="h-4 w-4" />
          <AlertTitle>Error</AlertTitle>
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}
      
      <div className="mt-8">
        {stage === 'setup' && renderSetup()}
        {stage === 'osint' && renderOsintUpload()}
        {stage === 'assessment' && !showRecommendations && renderQuestion()}
        {showRecommendations && renderRecommendations()}
      </div>
      {saveStatus && (
        <Alert variant={saveStatus.includes('failed') ? 'destructive' : 'default'} className="mb-4">
          <AlertCircle className="h-4 w-4" />
          <AlertTitle>{saveStatus}</AlertTitle>
        </Alert>
      )}
    </div>
  );
};

export default CyberAssessment;
