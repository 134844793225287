// src/App.js

import React, { useState, useContext } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { AuthProvider, AuthContext } from './contexts/AuthContext';
import CyberAssessment from './components/CyberAssessment';
import LanguageSelector from './components/LanguageSelector';
import Login from './components/Login';
import Register from './components/Register';
import AdminPanel from './components/AdminPanel';
import { Card, CardContent, CardHeader, CardTitle } from './components/ui/card';
import { Button } from './components/ui/button';
import { Alert, AlertDescription } from './components/ui/alert';

function ErrorFallback({error}) {
  return (
    <div role="alert" className="error-boundary">
      <h2>Oops! Something went wrong.</h2>
      <p>Error: {error.message}</p>
    </div>
  )
}

const AppContent = () => {
  const { user, logout, loading } = useContext(AuthContext);
  const [language, setLanguage] = useState('en');
  const [showLogin, setShowLogin] = useState(true);
  const [error, setError] = useState(null);

  if (loading) {
    return <div className="flex justify-center items-center h-screen">Loading...</div>;
  }

  if (!user) {
    return (
      <div className="App min-h-screen bg-background flex flex-col items-center justify-center p-4">
        <Card className="w-full max-w-md">
          <CardHeader>
            <CardTitle className="text-2xl font-bold text-center">
              {language === 'en' ? 'Secure your future. Start Here' : 'Asegure su futuro. Empiece Aquí'}
            </CardTitle>
          </CardHeader>
          <CardContent>
            <LanguageSelector language={language} setLanguage={setLanguage} />
            
            {error && (
              <Alert variant="destructive" className="mb-4">
                <AlertDescription>{error}</AlertDescription>
              </Alert>
            )}
            
            {showLogin ? (
              <>
                <Login setError={setError} />
                <p className="text-center mt-4">
                  {language === 'en' ? "Don't have an account?" : "¿No tiene una cuenta?"}{' '}
                  <Button variant="link" onClick={() => setShowLogin(false)}>
                    {language === 'en' ? 'Register' : 'Regístrese'}
                  </Button>
                </p>
              </>
            ) : (
              <>
                <Register setError={setError} />
                <p className="text-center mt-4">
                  {language === 'en' ? 'Already have an account?' : '¿Ya tiene una cuenta?'}{' '}
                  <Button variant="link" onClick={() => setShowLogin(true)}>
                    {language === 'en' ? 'Login' : 'Inicie sesión'}
                  </Button>
                </p>
              </>
            )}
          </CardContent>
        </Card>
      </div>
    );
  }

  return (
    <div className="App min-h-screen bg-background">
      <header className="bg-primary text-primary-foreground p-4">
        <div className="container mx-auto flex justify-between items-center">
          <h1 className="text-2xl font-bold">
            {language === 'en' ? 'Secure your future. Start Here' : 'Asegure su futuro. Empiece Aquí'}
          </h1>
          <div className="flex items-center space-x-4">
            <LanguageSelector language={language} setLanguage={setLanguage} />
            <Button onClick={logout} variant="outline">
              {language === 'en' ? 'Logout' : 'Cerrar sesión'}
            </Button>
          </div>
        </div>
      </header> 
      <main className="container mx-auto p-4">
        {user.isAdmin ? (
          <AdminPanel />
        ) : (
          <CyberAssessment language={language} />
        )}
      </main>
      <footer className="bg-secondary text-secondary-foreground p-4 mt-8">
        <div className="container mx-auto text-center">
          <p>&copy; 2024 Cyber Celeste, LLC. {language === 'en' ? 'All rights reserved.' : 'Todos los derechos reservados.'}</p>
        </div>
      </footer>
    </div>
  );
};

const App = () => {
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <AuthProvider>
        <Router>
          <AppContent />
        </Router>
      </AuthProvider>
    </ErrorBoundary>
  );
};

export default App;
