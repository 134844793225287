import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './accordion.css';

export const Accordion = ({ children }) => {
  return <div className="accordion">{children}</div>;
};

export const AccordionItem = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="accordion-item">
      {React.Children.map(children, (child) => {
        if (child.type === AccordionTrigger) {
          return React.cloneElement(child, { isOpen, setIsOpen });
        }
        if (child.type === AccordionContent) {
          return isOpen ? child : null;
        }
        return child;
      })}
    </div>
  );
};

export const AccordionTrigger = ({ children, isOpen, setIsOpen }) => (
  <div className="accordion-header" onClick={() => setIsOpen(!isOpen)}>
    {children}
    <span className={`accordion-icon ${isOpen ? 'open' : ''}`}>
      {isOpen ? '−' : '+'}
    </span>
  </div>
);

export const AccordionContent = ({ children }) => (
  <div className="accordion-content">
    {children}
  </div>
);

Accordion.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
